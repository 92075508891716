/* src/components/MainContent.css */
.main-content {
    flex-grow: 1;
    padding: 50px;
    padding-bottom:0px; /* Restore padding here to keep the main content centered */
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    
    
  /* Border Radius per Corner */
  border-top-left-radius: 30px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 00px;
  border-bottom-left-radius: 0px;
  border-top: 1px solid #e0e0e0; /* Red top border */
 /* Magenta left border */
    margin: 0px;
    overflow-y: auto; /* Allow vertical scrolling */
    overflow-x: hidden;
    /* Adjust margin to create space below the TopBar */
  }
  
  .content-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px; /* Adjust padding to ensure content isn't pushed up */
  }
  
  
  
  .main-content h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .main-content p {
    color: #666;
    font-size: 18px;
    text-align: center;
    max-width: 100%;
    margin-bottom: 40px;
  }
  
  .actions {
    display: flex;
    gap: 20px;
    justify-content: center; /* Center buttons */
    margin-bottom: 40px;
  }
  
  .action-button {
    background-color: #ffffff;
    color: #333;
    border: 2px solid #e0e0e0;
    padding: 15px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .action-button:hover {
    background-color: #f0f0f0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .chat-input {
    display: flex;
    gap: 10px;
    max-width: 800px;
    margin: 0 auto; /* Center the input */
  }
  
  .chat-input input {
    flex-grow: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .chat-input button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .chat-input button:hover {
    background-color: #0056b3;
  }
  