/* ============================================================
   ProjectManager Component CSS
   File: src/components/ProjectManager.css
============================================================ */

/* ============================================================
   Project Manager Container Styles
=========================================================== */
.project-manager {
    flex-grow: 1;
    padding: 40px;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 30px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 1px solid #e0e0e0;
    margin: 0;
    
}

.project-content-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;   /* Align content to the top left */
    justify-content: flex-start; /* Ensure content starts from the top */
    padding-top: 0; 
    padding-bottom: 50px;
    overflow-y: auto;  
}

.project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1300px;
    margin-top: 0;              /* Remove extra top margin */
}

/* ============================================================
   Project Card Styles
=========================================================== */
.project-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;         /* Enable absolute positioning of delete button */
    text-align: center;         /* Center align the text */
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.add-project-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e6f7ff;  /* Slightly different color to highlight */
    border: 2px dashed #007bff; /* Dashed border to indicate it's for adding a new project */
}

.project-card h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
}

.project-card p {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}

.project-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.project-users {
    font-size: 14px;
    color: #999;
}

.project-star {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #ffca28;
}

.project-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin-bottom: 0;          /* Add space between title and footer */
}

/* ============================================================
   Buttons and Inputs
=========================================================== */
.project-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.create-project-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
}

.create-project-button:hover {
    background-color: #0056b3;
}

.delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #e74d3c56;          /* Red color for delete icon */
    font-size: 10px;
    transition: color 0.3s;
}

.delete-button:hover {
    color: #c0392b;             /* Darker red on hover */
}

.add-button {
    position: absolute;
    background: none;
    border: none;
    top: 10px;
    left: 10px;
    cursor: pointer;
    color: #007bff;             /* Blue color for the add icon */
    font-size: 20px;
    transition: color 0.3s;
    margin-left: 10px;
}

.add-button:hover {
    color: #0056b3;             /* Darker blue on hover */
}

/* ============================================================
   Confirmation Modal Styles
=========================================================== */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 999;                          /* Ensure it covers the main content but is below the confirmation box */
}

.confirmation-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 300px;
    text-align: center;
}

/* Styling for the text inside the confirmation box */
.confirmation-box p {
    font-size: 16px;            /* Adjust font size */
    color: #333;                /* Text color */
    margin-bottom: 20px;        /* Space below the text */
}

/* Container for the buttons */
.confirmation-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

/* Style for the confirm (Yes, Delete) button */
.confirm-button {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.confirm-button:hover {
    background-color: #c9302c;  /* Darker red on hover */
}

/* Style for the cancel button */
.cancel-button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cancel-button:hover {
    background-color: #5a6268;  /* Darker grey on hover */
}

/* ============================================================
   Search Bar and Filter Tabs Styles
=========================================================== */
.search-bar {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
    border: none;
}

.search-input {
    padding: 8px 12px;
    border: 1px solid #ccc;
    outline: none;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    max-width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.filter-tabs {
    display: flex;
    gap: 10px;
    justify-content: center;
    outline: none;
    box-shadow: none;
}

.filter-tabs button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: 20px;
    transition: background-color 0.3s;
}

.filter-tabs button:hover {
    background-color: #0056b3;
}

.filter-tabs button.active {
    background-color: #0056b3;  /* Change background for active */
}
/* In ProjectManager.css */

.edit-project-input {
    margin-top: 20px;
    width: 100%;
    font-size: 1.5em;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .edit-project-input:focus {
    outline: none;
    border-color: #007bff;
  }
  