.parent-signup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.parent-signup-wrapper {
  background-color: #fff;
  max-width: 1500px;
  width: 90%;
  height: 80%;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.parent-signup-form-section {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}

.parent-signup-info-section {
  flex: 1;
  background-color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
}

.parent-signup-info-section img {
  max-width: 50%; /* Reduced size */
  height: auto;
}

.parent-signup-info-section p {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}

.input-group {
  margin-bottom: 20px;
}

.input-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.input-group input:focus {
  border-color: #007bff;
  outline: none;
}

.signup-button,
.next-button,
.prev-button,
.close-button {
  width: 100%;
  padding: 14px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.signup-button {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
}

.signup-button:hover {
  background-color: #0056b3;
}

.next-button {
  background-color: #28a745;
  color: white;
}

.prev-button {
  background-color: #6c757d;
  color: white;
}

.close-button {
  background-color: #007bff;
  color: #333;
}

.close-button:hover {
  background-color: #e0e0e0;
}
.parent-signup-info-section {
  margin-top: 20px; /* Space between the image and text */
  font-size: 16px;
  color: #ffffff;
  line-height: 1.5;
  text-align: center;
  display: flex;
  flex-direction: column; /* Ensures vertical stacking of children */
  justify-content: center;
  align-items: center;
  padding: 40px; /* Center the text */
}

p.signup-info-text {
  font-size: 18px;
  color: #f5f5f5;
  line-height: 1.5;
  text-align: center; /* Center the text */
}