/* ============================================================
   Modal Overlay Styles
============================================================ */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* ============================================================
   Modal Card Styles
============================================================ */
.modal-card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    max-width: 900px; /* Adjust as needed */
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* ============================================================
   Modal Header Styles
============================================================ */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal-header h2 {
    font-size: 24px;
    font-weight: 600;
}

.modal-close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

/* ============================================================
   Modal Tabs Styles
============================================================ */
.modal-tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.tab-button {
    background-color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 5px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px 5px 0 0;
    transition: background-color 0.3s;
}

.tab-button.active {
    background-color: white;
    border-bottom: 1px solid white;
    font-weight: bold;
    border: 1px dashed #007bff;
}

/* ============================================================
   Tab Content Styles
============================================================ */
.tab-content {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 0 5px 5px 5px;
}

.left-column,
.right-column {
    width: 48%; /* Adjust the width to fit the columns */
}

.full-width {
    width: 100%;
}

/* ============================================================
   Modal Section Styles
============================================================ */
.modal-section {
    margin-bottom: 20px;
}

.modal-label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}

.modal-input,
.modal-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box; /* Ensure padding is included in width */
}

.modal-textarea {
    resize: none; /* Prevent resizing */
}

/* ============================================================
   Attachment Container Styles
============================================================ */
.attachment-container {
    border: 2px dashed #007bff;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
}

.attachment-container:hover {
    background-color: #05a00016;
}

.file-upload-label {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    width: calc(100% - 20px); /* Adjusted calculation */
}

.plus-icon {
    margin-right: 8px;
    z-index: 1000;
}

.upload-text {
    font-weight: bold;
}

/* ============================================================
   File List Styles
============================================================ */
.file-list {
    margin-top: 20px;
    list-style-type: none;
    padding: 0;
}

.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 5px;
}

.delete-icon {
    color: #e74c3c;
    cursor: pointer;
}

/* ============================================================
   Modal Footer Styles
============================================================ */
.modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.modal-save-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.modal-save-button:hover {
    background-color: #0056b3;
}

.modal-cancel-button {
    background-color: #ccc;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.modal-cancel-button:hover {
    background-color: #999;
}

/* ============================================================
   File Upload Input Styles
============================================================ */
.file-upload-input {
    display: none;
}

/* ============================================================
   Modal Body Styles
============================================================ */
.modal-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.modal-card .tab-content {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

/* ============================================================
   Modal Select Styles
============================================================ */
.modal-select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
}

.modal-select:focus {
    border-color: #007bff;
    outline: none;
}

/* ============================================================
   Icon Preview Styles
============================================================ */
.icon-preview {
    display: flex;
    justify-content: center; /* Centers the icon horizontally */
    align-items: center;     /* Centers the icon vertically */
    height: 100px;           /* Set a specific height for the preview area */
    margin-top: 10px;        /* Add some spacing above the preview */
    border: 1px solid #e0e0e0; /* Optional: add a border to the preview area */
    border-radius: 8px;        /* Optional: add rounded corners */
    background-color: #f9fafc; /* Optional: add a background color */
}

.icon-preview img {
    max-width: 100%;         /* Ensure the icon doesn't overflow the container */
    max-height: 100%;        /* Ensure the icon doesn't overflow the container */
    display: block;          /* Ensure the image is treated as a block-level element */
}

/* ============================================================
   Color Grid Styles
============================================================ */
.color-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns in the grid */
    gap: 5px;              /* Space between the color blocks */
    justify-items: center; /* Center the color blocks horizontally */
}

.color-block {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* Optional: Rounded corners */
}
