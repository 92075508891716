/* src/components/TopBar.css */
.top-bar {
  width: 100%; /* Full width of the screen */
  height: 80px; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  box-sizing: border-box;
  z-index: 1; /* Ensure it stays above other content */
  position: relative; /* Ensure the button is positioned correctly */
  transition: height 0.3s ease; /* Smooth transition for height change */
}

.top-bar.minimized {
  height: 2.5%; /* Height when minimized */
}

.top-bar-content {
  display: flex;
  align-items: center;
}

.top-bar-icon {
  font-size: 30px;
  margin-right: 10px;
  padding-left: 20px; /* Space between icon and title */
}

.top-bar-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  
}

.toggle-topbar-button {
  display: none; /* Initially hidden */
  align-items: left;
  justify-content: left;
  cursor: pointer;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: absolute;
  bottom: -20px; /* Position it partially outside the bottom of the top bar */
  right: 50%; /* Center horizontally */
  transform: translateX(50%); /* Ensure it is centered horizontally */
}

.top-bar:hover .toggle-topbar-button {
  display: flex; /* Show the button on hover */
}

.toggle-topbar-button:hover {
  background-color: #f0f0f0;
}
.top-bar-content {
  display: flex;
  align-items: center;
}

.top-bar-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.top-bar-project-name {
  font-size: 1rem;
  margin-left: 5px;
  color: #007bff ;
   /* Example styling */
}
