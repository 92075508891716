/* Ensure body can scroll only vertically */

  
  /* Main content area */

  /* Responsive video container */
  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  
  /* Responsive iframe */
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    border: 0;
  }
  
  /* Tutorial section spacing */
  .tutorial-section {
    margin-bottom: 0px;
    padding: 0 0px;
    text-align: center;
  }
  /* Reset or control the margin and padding for headings */
.tutorial-section h2 {
    margin: 10px 0;
     /* Reduce the top and bottom margin */
    padding: 0; /* Remove any unwanted padding */
    font-size: 24px; /* Adjust font size as needed */
    text-align: center;
  }
  .quick-links-sidebar {
    width: 25%; /* Take up 25% of the width */
    position: sticky;
    top: 20px; /* Keep it fixed while scrolling */
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  
  .quick-links-sidebar h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .quick-links-sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .quick-links-sidebar li {
    margin-bottom: 10px;
  }
  
  .quick-links-sidebar a {
    text-decoration: none;
    color: #007bff;
  }
  
  .quick-links-sidebar a:hover {
    text-decoration: underline;
  }
  /* Styling for Intro Paragraph */
.intro-text {
  font-size: 18px;
  color: #555; /* Adjust as needed */
  margin-bottom: 20px;
}

/* Styling for Student Login Paragraph */
.student-login-text {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
  text-align: left; /* Example customization */
}

/* Styling for Parent Login Paragraph */
.parent-login-text {
  font-size: 16px;
  color: #666;
  font-style: italic; /* Example customization */
}

/* Styling for Create Project Paragraph */
.create-project-text {
  font-size: 16px;
  color: #444;
  font-weight: bold; /* Example customization */
}

/* Styling for Draw Tool Paragraph */
.draw-tool-text {
  font-size: 16px;
  color: #007bff; /* Example customization */
  text-decoration: underline; /* Example customization */
}
/* Quick Menu Bar Styling */
.quick-menu-bar {
  background-color: #f9fafc; /* Matches the sidebar background */
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
  position: sticky;
  top: 0; /* Sticks to the top of the viewport */
  z-index: 1000;
}

.quick-menu-title {
  font-size: 20px;
  font-weight: bold;
  color: #333; /* Matches text color */
  margin-bottom: 10px;
}

.quick-menu-list {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.quick-menu-list li {
  margin: 0;
}

.quick-menu-list li a {
  text-decoration: none;
  color: #0573e8; /* Matches button hover color */
  font-weight: 600;
}

.quick-menu-list li a:hover {
  text-decoration: underline;
  color: #0056b3; /* Slightly darker hover color */
}
