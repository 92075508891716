.ai-chat-layout {
    display: flex;
    height: 100vh;
    
  }
  
  .left-shell-sidebar {
    width: 10%;
    background-color: #f9fafc;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: width 0.3s ease;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 40px;
    background-color: #ffffff;
    background-image: radial-gradient(#d2dadf 1px, transparent 1px);
    background-size: 20px 20px;
    display: flex;
    flex-direction: column;
    margin: 0;
    position: relative;
    transition: padding-right 0.3s ease;
    overflow-y: hidden;
  }
  
  .right-sidebar {
    width: 220px; /* New toolbar */
    background-color: #f9fafc;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: width 0.3s ease;
  }
  
  .content-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    margin-left:80px;
    padding-top: 0px;
    padding-right: 0px;
    
  }
  
  .voiceflow-chat-container {
    width: 100% - 100px;
    height: 100%;
    border-radius: 10px;
    background-color: transparent;
    
  }
  .no-project-message {
    text-align: center;
    margin-top: 20px;
  }
  
  .create-project-link {
    margin-top: 10px;
  }
  
  .create-project-link a {
    display: inline-block;
    color: #007bff; /* Adjust the color as needed */
    text-decoration: none;
  }
  
  .create-project-link a:hover {
    text-decoration: underline;
  }
  .login-prompt {
    text-align: center;
    margin-top: 20px;
  }
  
  .login-link {
    margin-top: 10px;
  }
  
  .login-link a {
    display: inline-block;
    color: #007bff; /* Adjust the color as needed */
    text-decoration: none;
  }
  
  .login-link a:hover {
    text-decoration: underline;
  }
  /* Unique styling for the toggle button */

  .chat-type-dropdown-container {
    position: absolute;
    top: 10px;
    left: 30px;
    z-index: 1000;
  }
  
  .chat-type-toggle-button {
    background-color: transparent;
    color: rgb(0, 10, 105);
    border: none;
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .chat-type-toggle-button:hover {
    background-color: #e9e9e9;
  }
  
  .chat-type-dropdown-menu {
    position: absolute;
    top: 40px;
    left: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }
  
  .chat-type-dropdown-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .chat-type-dropdown-item:hover {
    background: #f0f0f0;
  }
  .chevron-icon {
    margin-left: 0px;    /* Adjusts horizontal position */
    font-size: 15px;      /* Adjusts icon size */
    position: relative;   /* Enables fine-tuning with top/bottom properties */
    top: 2px;             /* Moves the icon down slightly */
    color: rgb(0, 10, 105);          /* Optional: Adjusts color */
  }
  