/* ============================================================
   Sidebar Container Styles
============================================================ */
.sidebar {
    width: 240px; /* Fixed full width */
    background-color: #f9fafc; /* Light background */
    color: #636363;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid #e0e0e0; /* Light border */
    font-family: 'Inter', sans-serif;
    flex-shrink: 0;
    overflow: visible; /* Prevent content overflow when minimized */
}

/* ============================================================
   Sidebar Minimization Styles
============================================================ */
.sidebar.minimized {
    width: 60px; /* Width when minimized */
    transition: width 0.3s ease;
}


/* ============================================================
   Logo Styles
============================================================ */
.logo {
    display: flex;
    align-items: center;
    padding: 10px 0;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 0px; /* Adjusted margin */
}

.logo-img {
    width: 40px;
    height: auto;
    margin-right: 10px;
}

/* Logo when sidebar is minimized */
.sidebar.minimized .logo img {
    margin-right: 0;
}

.sidebar.minimized .logo span {
    opacity: 0;            /* Hide text when minimized */
    visibility: hidden;
}

.sidebar:not(.minimized) .logo span {
    opacity: 1;            /* Show text when expanded */
    visibility: visible;
}

/* ============================================================
   Toggle Button Styles
============================================================ */
.toggle-button {
    padding-left: 23px;
}

.toggle-button.rotated {
    transform: rotate(180deg); /* Rotate 180 degrees */
  }
  .toggle-button:hover {
    color: #000a69; /* Change color on hover */
  }
  

/* ============================================================
   Navigation Menu Styles
============================================================ */
.sidebar nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar nav ul li {
    padding-left: 0px;
    margin-bottom: 10px; /* Spacing between items */
    transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition */
}

/* Menu items when sidebar is minimized */
.sidebar.minimized nav ul li {
    transform: translateX(0); /* Keep items in place */
    opacity: 1;               /* Keep items visible */
    visibility: visible;      /* Ensure visibility */
}

/* ============================================================
   Menu Item Styles
============================================================ */
.sidebar nav ul li a {
    text-decoration: none;
    color: #636363;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 12px 16px; /* Adjust padding for spacing */
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition */
    position: relative; /* For potential icons/badges */
}

/* Highlight active menu item */
.sidebar nav ul li.active a {
    background-color: #d1efff52; /* Highlight background */
    color: #333;                 /* Text color */
}

/* Menu item icons */
.sidebar nav ul li a .icon {
    margin-right: 12px; /* Icon spacing */
    font-size: 20px;    /* Fixed icon size */
    padding-left: 5px;
    /* Icon color */
}

/* Menu item hover effect */
.sidebar nav ul li a:hover {
    background-color: rgba(218, 237, 251, 0.414); /* Subtle hover effect */
    color: #333;
}

/* Badge inside menu item */
.sidebar nav ul li a .badge {
    margin-left: auto;
    background-color: #007bff;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
}

/* ============================================================
   Minimized Sidebar Styles
============================================================ */
/* Hide text in menu items when minimized */
.sidebar.minimized nav ul li a span {
    opacity: 0;
    transform: translateX(-20px); /* Slide text left */
    visibility: hidden;
}

/* Icons alignment when minimized */
.sidebar.minimized nav ul li a .icon {
    margin-right: 0;
    justify-content: center;
    transition: none; /* Prevent unnecessary transitions */
}

/* Show text when sidebar is expanded */
.sidebar:not(.minimized) nav ul li a span {
    opacity: 1;
    transform: translateX(0); /* Reset position */
    visibility: visible;
}

/* ============================================================
   Light/Dark Mode Toggle Styles
============================================================ */
.settings {
    margin-top: auto;
}

.light-dark-toggle {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px; /* Spacing between buttons */
}

.light-dark-toggle button {
    background-color: #f0f0f0;
    color: #555;
    border: none;
    padding: 8px 17px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px; /* Fixed text size */
}

.light-dark-toggle button:hover {
    background-color: #e0e0e0;
}

/* Toggle styles when minimized */
.sidebar.minimized .light-dark-toggle {
    flex-direction: column;
    padding: 4px 8px; /* Smaller padding */
    font-size: 8px;   /* Smaller font size */
    border-radius: 3px;
    /* Stack buttons vertically */
}

/* ============================================================
   User Profile Styles
============================================================ */
.user-profile {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
}

.user-badge-wrapper {
    display: flex;
    align-items: center;
    justify-content: center; /* Center items horizontally */
    background-color: #eeebeb; /* Background color */
    border-radius: 8px;        /* Rounded corners */
    padding: 5px 10px;         /* Consistent padding */
    width: 100%;
    box-sizing: border-box;
    transition: background-color 0.3s; /* Smooth transition */
}

.user-badge-wrapper:hover {
    background-color: #e0e0e0; /* Hover effect */
}

.user-icon {
    font-size: 20px;     /* User icon size */
    color: #000000;      /* User icon color */
    margin-right: 20px;
    margin-left: -10px;  /* Space between icon and badge */
}

.user-badge {
    width: 30px;
    height: 30px;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    flex-shrink: 0;
}

.user-tooltip {
    margin-left: 10px; /* Adjust as needed */
    color: #636363;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    display: none;      /* Prevent line breaks */
}

/* Hide tooltip when minimized */
.sidebar.minimized .user-tooltip {
    opacity: 0;
    transform: translateX(-20px); /* Slide text left */
    visibility: hidden;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Show tooltip when expanded */
.sidebar:not(.minimized) .user-tooltip {
    opacity: 1;
    transform: translateX(0); /* Reset position */
    visibility: visible;
}

/* Hide user icon when minimized */
.sidebar.minimized .user-icon {
    display: none;
    transition: opacity 0.3s ease;
}

/* ============================================================
   Divider Line Styles
============================================================ */
.divider-line {
    border-top: 1px solid #e0e0e0; /* Always visible */
    margin: 20px 0;                /* Vertical spacing */
}

/* ============================================================
   Navigation Gap Styles
============================================================ */
.nav-gap {
    margin-top: 200px;  /* Adjust for desired gap size */
    margin-bottom: 10px; /* Optional spacing below */
}

.nav-gap::before {
    content: "";
    display: block;
}

/* ============================================================
   Media Queries
============================================================ */
/* Prevent icons from resizing on smaller screens */
@media screen and (max-width: 100px) {
    .sidebar {
        width: 60px; /* Keep sidebar minimized */
    }

    .sidebar.minimized .logo img,
    .sidebar.minimized .logo span,
    .sidebar.minimized nav ul li a span,
    .sidebar.minimized .user-profile span {
        font-size: 16px;
    }

    .sidebar nav ul li a .icon {
        font-size: 20px; /* Fixed icon size */
    }
}

/* ============================================================
   Spinner Styles
============================================================ */
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

@keyframes spin {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.custom-spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #007bff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}
.lightning-text {
    color: #fff; /* Base text color */
    font-weight: bold;
    position: relative;
    /* Optional: To make the text color slightly blue */
    color: #000a69; /* You can adjust this for a slight tint */
  }
  /* Tooltip container */
/* Tooltip styles */
.sidebar.minimized [data-tooltip] {
    position: relative;
  }
  
  .sidebar.minimized [data-tooltip]::after {
    content: attr(data-tooltip);
    position: absolute;
    left: 100%; /* Position to the right of the element */
    top: 50%;
    transform: translate(10px, -50%);
    background-color: #333;
    color: #fff;
    padding: 5px 8px;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
    transition: opacity 0.1s;
  }
  
  .sidebar.minimized [data-tooltip]:hover::after {
    opacity: 1;
    visibility: visible;
  }
  
 
  