/* ============================================================
   Login Component CSS
   File: src/components/Login.css
============================================================ */

/* ============================================================
   Container Styles
============================================================ */
.login-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  /* All other styles should be scoped under .login-container */
  .login-container .container {
    display: flex;
    width: 60%;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0);
    border-radius: 15px;
  }
  
  .login-container .left-panel,
  .login-container .right-panel {
    padding: 40px;
  }
  
  /* ============================================================
     Left Panel Styles
  ============================================================ */
  .login-container .left-panel {
    flex: 1;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  
  /* ============================================================
     Right Panel Styles
  ============================================================ */
  .login-container .right-panel {
    flex: 1;
    background-color: #007bff;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  
  /* Variant Background Colors */
  .student-active .right-panel {
    background-color: #2dbd06; /* Green for Student */
  }
  
  .parent-active .right-panel {
    background-color: #ff8c34; /* Orange for Parent */
  }
  
  /* ============================================================
     Brand and Logo Styles
  ============================================================ */
  .login-container .brand {
    display: flex;
    align-items: center;
    margin-bottom: 20px; /* Adjust spacing */
  }
  
  .login-container .brand img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  /* ============================================================
     Form Styles
  ============================================================ */
  .login-container form {
    margin-top: 20px;
  }
  
  .login-container .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
  }
  
  .login-container .input-group input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  /* Password Visibility Icon */
  .login-container .input-group .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #007bff;
    font-weight: bold;
    font-size: 14px;
  }
  
  .login-container .input-group .icon:hover {
    color: #0056b3;
  }
  
  /* ============================================================
     Button Styles
  ============================================================ */
  .login-container button {
    width: 100%;
    padding: 10px;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .login-container button:hover {
    background-color: #0056b3;
  }
  
  /* Specific Buttons */
  .login-button {
    background-color: #4285F4;
  }
  
  .google-signin-button {

    padding: 0px;
    margin-top: 10px;
    margin-bottom: 20px; /* Adjust this value to control the spacing */
    border: none;
    background-color: #95acd1; /* Google Blue */
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: background-color 0.3s;
  }
  
  .google-signin-button:hover {
    background-color: #f5f5f9; /* Lighter shade */
  }
  
  .student-login-button {
    margin-top: 5px;
    background-color: #2dbd06; /* Green for Student Login */
  }
  
  .student-login-button:hover {
    background-color: #269d05;
  }
  
  /* Student Buttons */
  .student-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .student-button {
    background-color: #4285F4;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 2px;
  }
  button.student-register-button {
    background-color: transparent;
    color: #0056b3; /* This will override the previous rule */
  }
  button.student-register-button:hover {
    background-color: transparent;
    color: #014186;
  }
  .student-button:hover {
    background-color: #45a049;
  }
  
  /* Portal Buttons */
  .student-portal-button {
    background-color: #28a745; /* Green for student portal */
  }
  
  .parent-portal-button {
    background-color: #ffc107; /* Yellow for parent portal */
  }
  
  /* ============================================================
     Illustration Styles
  ============================================================ */
  .login-container .ai-illustration {
    width: 150px;
    height: auto;
    margin-top: 20px;
  }
  
  .login-container .illustration {
    text-align: center;
  }
  
  .login-container .illustration h2 {
    margin-bottom: 10px;
  }
  
  .login-container .illustration img {
    margin-bottom: 10px;
  }
  
  .login-container .illustration p {
    margin-top: 10px;
    font-size: 14px;
  }
  
  /* ============================================================
     Form Toggle Text and Error Message
  ============================================================ */
  .form-toggle-text {
    font-size: 14px;
    margin-bottom: 20px; /* Space between text and form */
    color: #333;
  }
  
  .form-toggle-text a {
    color: #007bff;
    text-decoration: none;
    font-weight: 600;
  }
  
  .form-toggle-text a:hover {
    text-decoration: underline;
  }
  
  .form-toggle-text:first-child {
    justify-content: flex-start; /* Align the first link to the left */
    text-align: left;
  }
  
  .error-message {
    color: #ff4d4f; /* Red color for error message */
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  /* ============================================================
     Google Logo
  ============================================================ */
  .google-logo {
    width: 20px;
    height: 20px;
  }
  /* ============================================================
     Forgot Password Styles
  ============================================================ */

  .forgot-password-text {
    margin-top: 10px;
    text-align: right;
    
  }
  
  .forgot-password-text a {
    color: #007bff;
    text-decoration: none;
    font-size: 10px;
  }
  
  .forgot-password-text a:hover {
    text-decoration: underline;
  }
  /* In your Login.css or global.css */
.parent-signup-button {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  background-color: #f0ad4e; /* Adjust the color as needed */
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.parent-signup-button:hover {
  background-color: #ec971f; /* Adjust hover color as needed */
}
