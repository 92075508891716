/* src/styles/global.css */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
  }
  .user-badge {
    background-color: #000a69;
}
.top-bar {
  background-color: #f9fafc;
}
.top-bar-project-name {
  color: #000a69;
}
.sidebar nav ul li a .icon {
  color: #000a69;
}
.toolbar-icon {
  color: #000a69 ;
  /* Ensures tooltip is positioned relative to each icon */
}
.lightning-text {
  color: #000a69; /* You can adjust this for a slight tint */
}
:root {
  /* Color Variables */
  --background-color: #f9fafc !important;
  --main-bg-color: #ffffff !important;
  --text-color: #333 !important;
  --sidebar-bg-color: #f9fafc !important;
  --top-bar-bg-color: #f9fafc !important;
  --right-sidebar-bg-color: #f9fafc !important;
  --border-color: #e0e0e0 !important;
  --icon-color: #e0e0e0 !important;
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto; /* Always enable vertical scroll */
}
