.content-toolbar {
    width: 20%;
    background-color: white;
    position: absolute;
    right: 0; /* Stick to the right of the main content */
    top: 0;
    bottom: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-left: 1px solid #ddd;
    transition: width 0.2s ease;
    
     /* Smooth transition */
  }
  
  .content-toolbar.minimized {
    width: 20px; /* Set the width when minimized */
    padding: 20px 5px;
    justify-content: center; /* Center the icons vertically when minimized */
    transition: width 0.1s ease;
  }
  
  
  .toolbar-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align icons at the top when expanded */
    align-items: center; /* Center icons horizontally */
  }
  
  .content-toolbar.minimized .toolbar-content {
    justify-content: center; /* Center icons vertically when minimized */
  }
  .content-toolbar.minimized .toolbar-header {
    justify-content: center; 
    /* Space between items when minimized */
     /* Optional: Add padding to create some space */
  }
  .toolbar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  
  .title-icon {
    padding-top: 20px;
    cursor: default; /* Title icon is not clickable */
    font-size: 20px; /* Adjust the size of the title icon */
  }
  
  .toolbar-minimize-icon {
    cursor: pointer;
    margin-left: 10px;
    font-size: 15px;
    color: #007bff; 
    /* Adjust size for the toolbar's minimize icon */
  }
  
  .toolbar-minimize-icon:hover {
    color: #0056b3;
    font-size: 20px; /* Optional: Change size on hover */
  }
  
  .toolbar-icon {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    justify-content: center;
    color: #272179;
    position: relative; /* Ensures tooltip is positioned relative to each icon */
  }
  
  .toolbar-icon span {
    margin-left: 10px;
  }
  
  .toolbar-icon:hover {
    color: #007bff;
  }
  
  .toolbar-icon:hover .tooltip-text {
    visibility: visible; /* Show tooltip on hover */
    opacity: 1;
  }
  
  .tooltip-text {
    visibility: hidden; /* Initially hidden */
    width: 100px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    right: 150%; /* Position to the left of the icon */
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip-text::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%; /* At the right edge of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #555; /* Flip the arrow */
  }
  
  /* Minimize/Expand button styling */
  .toggle-toolbar-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 10px;
    position: absolute;
    top: 50%; /* Position at the top of the toolbar */
    left: -30px; /* Positioned outside the toolbar */
    z-index: 1;
  }
  
  .toggle-toolbar-button:hover {
    background-color: #f0f0f0;
  }
  
  .main-icon {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .file-list {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 80%; /* Limit the height of file list */
     /* Scroll if content exceeds height */
  }
  
  .file-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    position: relative;
    text-overflow: ellipsis;
  }
  
  .file-list li a {
    text-decoration: none;
    color: #007bff;
    display: inline-block;
    max-width: 60%; /* Limit file name width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis for long file names */
  }
  
  .file-list li a:hover {
    text-decoration: underline;
  }
  
  /* Progress circle styling for the file upload percentage */
  .file-progress {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid #007bff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #007bff;
    display:none;
  }
  
  .file-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
  
  .upload-file-header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Upload file input styling */
  .upload-file-input {
    padding: 10px;
    padding-top: 50px;
    padding-bottom: 50px;
    border: 2px dashed #007bff;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    background-color: #f9f9f9;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .upload-file-input:hover {
    background-color: #f0f0f0;
  }
  
  .file-size {
    font-size: 12px;
    color: #666;
    margin-left: 10px;
  }

  .upload-file-container {
    padding-top:20px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers the container vertically */
    align-items: center; /* Centers the content horizontally */
    /* Makes the container take up the full height of the toolbar */
    width: 100%;
    
  }
  .delete-icon {
    cursor: pointer;
    padding-top:5px;
    color: rgba(233, 8, 8, 0.342) !important;
    padding-left:5px; 
    font-size: 12px; 
    flex-shrink: 0;/* You can change this to any color */
    
  }
  .loading-icon {
    margin-left: 10px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  .prompts-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .prompts-content h3 {
    margin-bottom: 30px; /* Adjust the space below the heading */
  }
  .prompt-button {
    background-color: #255cca; /* Blue background */
    color: white;
    font-size: 14px;
    padding: 10px 15px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    transition: background-color 0.2s ease;
  }
  
  .prompt-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .prompt-button:active {
    background-color: #004080; /* Even darker blue when clicked */
  }

  .disabled-icon {
    opacity: 0.5;          /* Dim the icon */
    pointer-events: none;  /* Disable interaction */
    cursor: not-allowed;   /* Show disabled cursor */
  }
  