/* General settings box styles */
.settings-box {
    position: fixed;
    bottom: 80px; /* Increase this value to add more gap */
    left: 120px;
    transform: translateX(-50%);
    width: 200px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 9999;
    transition: all 0.3s ease;
}


/* Adjust positioning for minimized sidebar */
.sidebar.minimized .settings-box {
    left: 10px; /* Shift it to align with the minimized sidebar */
    transform: none; /* Remove centering when minimized */
    right: auto; 
    border-radius: 8px;/* Ensure the right is not affecting minimized state */
}

/* Additional hover styles for settings items */
.settings-item {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.settings-item:hover {
    background-color: #f9f9f9;
    
}

.settings-icon {
    margin-right: 10px;
}
