/* ============================================================
   RightSidebar Component CSS
   File: src/components/RightSidebar.css
============================================================ */

/* ============================================================
   Sidebar Container Styles
============================================================ */
.right-sidebar {
  width: 15%;
  background-color: #f9fafc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  transition: width 0.3s ease;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 50px;
   /* Smooth transition for minimizing */
}

.right-sidebar.minimized {
  width: 2px; /* Set the width when minimized */
  padding-top: 25px; /* Keep consistent padding */
  justify-content: flex-start; /* Align content to the top even when minimized */
  align-items: center;
  transition: width 0.3s ease;
}

/* ============================================================
   Sidebar Content Styles
============================================================ */
.right-sidebar-content {
  flex-grow: 1;
  margin-top: -5px;
  
}

.right-sidebar-content h2 {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure space for the add button */
}

.right-sidebar.minimized .right-sidebar-content ul {
  display: none; /* Hide the list when minimized */
  align-items: center;
}

/* ============================================================
   Icons and Buttons
============================================================ */
.projects-icon {
  font-size: 10px; /* Fixed size for the icon */
  flex-shrink: 0;
}

.add-project-icon {
  font-size: 16px;
  color: #007bff;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 10px;
}

.toggle-rightbar-button {
  display: none; /* Initially hidden */
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: absolute;
  top: 50%; /* Position the button inside the sidebar */
  left: -18px; /* Position partially outside the right edge */
  z-index: 100;
  
}

.right-sidebar:hover .toggle-rightbar-button {
  display: flex; /* Show the button on hover */
 
}

.toggle-rightbar-button:hover {
  background-color: #f0f0f0;
}

.minimize-icon {
  font-size: 20px;
  color: white; /* Adjust the color as needed */
}

/* ============================================================
   Project List Styles
============================================================ */
.right-sidebar ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
}

.right-sidebar ul li {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  border-radius: 3px;
}

.right-sidebar ul li:hover {
  background-color: #fcfbfb;
}

.right-sidebar ul li.active {
  background-color: #d1efff52;
  color: #333;
}

/* ============================================================
   Popup Styles
============================================================ */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: #e6f7ff; /* Match the background color of the Project Manager's add project card */
  padding: 20px;
  border-radius: 15px; /* Match the border radius */
  text-align: center;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Match the shadow */
  box-sizing: border-box; /* Ensure padding is included in width */
  border: 2px dashed #007bff; /* Match the dashed border */
}

.popup-content h3 {
  margin-bottom: 15px;
  font-size: 18px;
}

.popup-content input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 2px solid #e0e0e0;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.popup-actions {
  display: flex;
  justify-content: space-between;
}

.popup-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.popup-actions button:first-child {
  background-color: #007bff;
  color: white;
}

.popup-actions button:last-child {
  background-color: #ccc;
  color: black;
}
