/* VoiceTranscription.css */
.voice-controls {
    display: flex;
    justify-content: center; /* Center the button horizontally */
    align-items: center; /* Center the button vertically */
    width: 100%; /* Full width to ensure proper centering */
    margin: 0 auto 20px;
     /* Center the button container and add margin */
  }
  
  .voice-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
  }
  .voice-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .voice-controls {
    display: flex;
    justify-content: center; /* Center the button horizontally */
    align-items: center; /* Center the button vertically */
    width: 100%; /* Full width to ensure proper centering */
    margin: 20px 0; /* Optional: Add some spacing above and below */
  }
  
  .voice-content .transcript {
    color: white !important; /* Light Green with higher specificity */
    font-size: 18px;
    line-height: 1.5;
    font-weight: bold;
  }
  
  .transcript-container {
    width: 80%; /* Match the width of the transcript box */
    max-width: 800px;
    margin: 20px auto;
    background: #031525;
    padding: 15px;
    border-radius: 10px;
    text-align: left;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease, max-height 0.3s ease; /* Smooth transition */
    max-height: 200px; /* Set a max height for transitions */
    opacity: 1; /* Fully visible */
    display: block; /* Default to block display */
  }
  
  /* Show when transcribing */
  .show {
    opacity: 1;
    max-height: 200px;
    display: block;
  }
  
  /* Hide when not transcribing */
  .hide {
    opacity: 0; /* Fully transparent */
    max-height: 0; /* Collapse the height */
    overflow: hidden; /* Hide any overflow */
    display: none; /* Set display to none to fully hide */
  }
  
  
  /* Hologram Wrapper */
  .hologram-wrapper {
    margin-top: -50px; /* Space between the transcript and the hologram */
    width: 100%;
    text-align: center;
    display: none;
    border: none !important;
    outline: none !important;  /* Hide the hologram initially */
  }
  
  .hologram-wrapper.active {
    display: block;
    border: none !important; 
    outline: none !important;/* Show the hologram when listening */
  }
  
  .voice-hologram-container {
    width: 200px; /* Adjusted size of the hologram */
    height: 300px;
    margin: 0 auto;
    overflow: hidden;
    border: none !important; 
    outline: none !important;
    /* border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 123, 255, 0.6);*/
  }
  /* VoiceTranscription.css */

/* Target the specific canvas inside the hologram container */
.voice-hologram-container canvas {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
  .circle-button {
    width: 80px;
    height: 80px;
    border-radius: 50%; /* Make the button circular */
    background-color: #ff6347; /* Tomato color for the button */
    border: none; /* Remove default borders */
    color: white; /* White icon color */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px; /* Large icon size */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth color transition */
    margin-top:20%; /* Remove any existing margins */
  }
  
  .circle-button:hover {
    background-color: #ff4500; /* Darker shade of tomato */
  }
  
  .circle-button.active {
    background-color: #dc143c; /* Crimson color when active */
  }
  .voice-controls-heading {
    text-align: center;
    margin-top: 20%;;
    margin-bottom: 10px; /* Add spacing below the heading */
    font-size: 24px;
    font-weight: bold;
    color: #ffffff; /* White text */
    width: 100%; /* Full width */
    position: absolute; /* Keep heading at the top */
    top: 10px; /* Adjust for better positioning */
  }
  
  .voice-selection {
    margin-top: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Voice Selection Title */
  .voice-selection-title {
    margin-bottom: 20px;
    font-size: 18px;
  }
  
  /* Voice Buttons Container */
  .voice-buttons {
    display: flex;              /* Use flexbox layout */
    flex-wrap: wrap;            /* Allow buttons to wrap onto the next line */
    justify-content: center;    /* Center buttons horizontally */
    gap: 10px;                  /* Add spacing between buttons */
    max-width: 100%;            /* Ensure container doesn't exceed parent width */
  }
  
  /* Voice Button Styles */
  .voice-button {
    background-color: #394a5b;  /* Default button color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;  /* Smooth transitions */
    margin: 5px;                /* Adjust margin to control spacing */
    flex: 1 1 auto;             /* Allow buttons to grow and shrink */
    max-width: 200px;           /* Set a maximum width for each button */
  }
  
  /* Hover Effect */
  .voice-button:hover {
    background-color: #636363;  /* Darker shade on hover */
    transform: translateY(-2px);/* Slightly lift the button */
  }
  
  /* Active Effect */
  .voice-button:active {
    background-color: #003d80;  /* Even darker shade on click */
    transform: translateY(0);   /* Revert back to original position */
  }
  
  /* Active State for Selected Button */
  .voice-button.active {
    background-color: #28a745;  /* Green color for selected voice */
  }
  
  /* Adjustments for Smaller Screens */
  @media (max-width: 600px) {
    .voice-buttons {
      justify-content: center;  /* Center buttons on smaller screens */
    }
  
    .voice-button {
      flex: 1 1 100%;           /* Make buttons full-width on small screens */
      max-width: none;
    }
  }