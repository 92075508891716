.controls {
    display: flex;
    gap: 10px; /* Adds space between buttons */
    justify-content: center; /* Centers the controls horizontally */
    margin-bottom: 20px; /* Adds some spacing at the bottom */
  }
  
  .controls button {
    width: 70px;
    background-color: #007bff; /* Primary blue color */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 10px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds shadow for depth */
 
  }
  
  .controls button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-2px); /* Slight lift effect */
  }
  
  .controls button:active {
    background-color: #003d80; /* Even darker blue when active */
    transform: translateY(0); /* Reset lift when button is clicked */
  }
  
  .controls input[type="color"], .controls input[type="range"] {
    border: none;
    border-radius: 5px;
    padding: 5px;
    background-color: #f0f0f0; /* Light background for inputs */
    cursor: pointer;
    margin-left: 10px; /* Adds spacing for the inputs */
  }
  
  .controls input[type="color"]:hover, .controls input[type="range"]:hover {
    background-color: #e0e0e0; /* Slightly darker on hover */
  }
  
  
  .controls > * {
    margin-right: 10px;
  }
  
  canvas {
    margin-top:60px;
    border: 2px dashed #007bff !important; 
    width:100%;
  }
  
  .content-toolbar.expanded-draw {
    width: 600px; /* Extra expanded width for draw mode */
  }

  .send-button {
    width: 100px;
    padding: 10px;
    margin-top: 20px; /* Space between the canvas and the button */
    background-color: #28a745; /* Green color for send button */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center; /* Center the text */
    display: block;
    margin: 20px auto; /* Center the button horizontally */
    transition: background-color 0.3s ease;
  }
  
  .send-button:hover {
    background-color: #218838; /* Darker green on hover */
  }
  .send-button-container {
    display: flex;
    align-items: center; /* Align spinner with the button */
  }
  
  .send-button-container {
    display: flex;
    align-items: center; /* Align the spinner and button vertically */
    justify-content: center; /* Center content if needed */
    position: relative; /* Position spinner relative to the button */
  }
  
  .loading-spinner {
    width: 18px;  /* Adjust size */
    height: 18px;
    margin-left: 10px; /* Set the 2px gap between the button and spinner */
    border: 2px solid rgba(0, 0, 0, 0.1); /* Reduced the spinner's border width */
    border-top: 2px solid #007bff; /* Spinner color */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    visibility: hidden;
  }
  .loading-spinner.active {
    visibility: visible; /* Show spinner when active */
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  