/* General styling */


.profile-container {
    width: 90%;
    max-width: 1200px; /* Limit the max width for readability */
    background-color: #ffffff; /* White background for clean look */
    border-radius: 16px;
    padding-right:40px;
    padding-left:40px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 20px auto; /* Center the profile container */
}

/* Profile Image Placeholder */
.profile-image-container {
    display: flex;
    justify-content: center;  
    align-items: center;  
    margin-bottom: 40px; 
    margin-top:20px;
}

.profile-letter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #4A90E2; /* Soft blue */
    color: white;
    font-size: 50px;
    font-weight: bold;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border: 4px solid #ffffff;
}

/* Badges */
.badges-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    gap: 20px; /* Added gap between badges */
}

.badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px; /* Adjusted badge width */
    padding: 20px;
    background-color: #FFD700; /* Bright yellow badge */
    border-radius: 16px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.badge:hover {
    transform: translateY(-5px);
}

.badge p {
    font-size: 32px; /* Larger font for the numbers */
    font-weight: bold;
    color: #333;
    margin: 0;
}

.badge span {
    font-size: 14px;
    color: #555;
    margin-top: 10px;
}

/* User Information */
.user-info {
    margin-top: 40px;
    text-align: left;
    padding: 0 40px; /* Adjust padding for better spacing */
}

.info-group {
    margin-bottom: -20px;
    display: flex;
    align-items: center;
}

.info-group label {
    font-weight: bold;
    font-size: 14px;
    color: #333;
    width: 200px;
    margin-right: 20px;
}

.info-group p {
    padding: 12px;
    font-size: 14px;
    background-color: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    color: #333;
    flex-grow: 1;
}

/* Responsive */
@media (max-width: 768px) {
    .badges-container {
        flex-direction: column;
        gap: 15px;
    }

    .profile-container {
        padding: 20px;
    }

    .user-info {
        padding: 0 20px;
    }
}
